import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="bg-gray-100 py-12 px-6 lg:py-20 lg:px-16">
      <div className="container mx-auto max-w-4xl bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-light text-center text-[#5167FC] mb-6">
          Privacy Policy
        </h1>
        <p className="text-gray-700 text-sm lg:text-base mb-6">
          Your privacy is important to us at Akira Web Solutions. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services—including our flagship application, Eldrin AI. By using any of our services, you agree to the terms outlined in this Privacy Policy.
        </p>

        <div className="space-y-6">
          <div>
            <h2 className="text-lg font-semibold text-[#5167FC]">1. Information We Collect</h2>
            <p className="text-gray-600">
              We collect information that you provide to us, as well as information automatically collected when you use our services.
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>
                <strong>Personal Information:</strong> Name, email address, and payment details when you create an account or subscribe to a plan.
              </li>
              <li>
                <strong>Usage Data:</strong> Information about how you interact with our platform, including IP address, browser type, and device information.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-[#5167FC]">2. How We Use Your Information</h2>
            <p className="text-gray-600">
              Akira Web Solutions uses the information collected to:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Provide, operate, and improve our services.</li>
              <li>Personalize your experience by tailoring content and features.</li>
              <li>Process transactions and send you updates about your account.</li>
              <li>
                Ensure the security and integrity of our platform by monitoring usage and detecting potential fraud.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-[#5167FC]">3. Sharing Your Information</h2>
            <p className="text-gray-600">
              We do not sell your personal information to third parties. We may share your information with:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>
                <strong>Service Providers:</strong> Third-party vendors who assist us in providing our services, such as payment processors or hosting providers.
              </li>
              <li>
                <strong>Legal Obligations:</strong> If required by law or to protect the rights, property, or safety of Akira Web Solutions, our users, or others.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-[#5167FC]">4. Data Retention</h2>
            <p className="text-gray-600">
              We retain your personal information only for as long as necessary to provide our services or as required by applicable laws. Once your information is no longer needed, we securely delete or anonymize it.
            </p>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-[#5167FC]">5. Security</h2>
            <p className="text-gray-600">
              We implement industry-standard measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure.
            </p>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-[#5167FC]">6. Your Rights</h2>
            <p className="text-gray-600">
              Depending on your location, you may have the following rights:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>
                <strong>Access:</strong> Request a copy of the personal information we hold about you.
              </li>
              <li>
                <strong>Correction:</strong> Request corrections to inaccurate or incomplete data.
              </li>
              <li>
                <strong>Deletion:</strong> Request the deletion of your information, subject to legal and contractual obligations.
              </li>
              <li>
                <strong>Opt-Out:</strong> Opt-out of marketing communications by following the instructions in our emails.
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-[#5167FC]">7. Cookies and Tracking Technologies</h2>
            <p className="text-gray-600">
              We use cookies and similar technologies to enhance your experience, track user engagement, and improve our services. You can control your cookie preferences through your browser settings.
            </p>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-[#5167FC]">8. Changes to This Policy</h2>
            <p className="text-gray-600">
              We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. Your continued use of our services constitutes your acceptance of the revised policy.
            </p>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-[#5167FC]">9. Contact Us</h2>
            <p className="text-gray-600">
              If you have any questions about this Privacy Policy, please contact us at{" "}
              <a
                href="mailto:support@akiraws.com"
                className="text-[#6817FF] underline hover:text-blue-600"
              >
                connect@akirawebsolutions.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
